<template>
    <div>
        <div class="p-fluid">
            <div class="formgrid grid">
                <div class="field col-12 md:col-6 lg:col-3">
                    <label for="dropdownRegion">Region</label>
                    <Dropdown v-model="filters.region_id" :loading="loadingDropdownRegion" :options="dataDropdownRegion" optionLabel="region_name" optionValue="region_id" placeholder="Pilih Region" :filter="true" :showClear="true" @filter="searchDropdownRegion($event, 'filter')"/>
                </div>
                <div class="field col-12 md:col-6 lg:col-3">
                    <label for="dropdownKlasifikasi">Klasifikasi</label>
                    <Dropdown v-model="filters.klasifikasi_id" :loading="loadingDropdownKlasifikasi" :options="dataDropdownKlasifikasi" optionLabel="label" optionValue="klasifikasi_id" placeholder="Pilih Klasifikasi" :filter="true" :showClear="true" @filter="searchDropdownKlasifikasi($event, 'filter')"/>
                </div>
            </div>
        </div>
        <Button :loading="loading" label="Filter" icon="pi pi-search" class="p-button-warning mr-2 my-1" @click="getDataTable" />
    </div>
</template>

<script>

export default {
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownRegion: false,
            loadingDropdownKlasifikasi: false,

            // dataDropdown
            dataDropdownRegion: null,
            dataDropdownKlasifikasi: null,

            // filter
            filters: {
                region_id: null,
                klasifikasi_id: null,
            },
        }
    },
    mounted() {
        this.searchDropdownRegion('');
        this.searchDropdownKlasifikasi('');
    },
    created(){
    },
    watch: {
    },
    computed:{
    },
    methods: {
        // DROPDOWN
        searchDropdownRegion(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.drgn.filterValue = valueEdit;
                }

                if (purpose == "filter") {
                    this.loadingDropdownRegion = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-region-v2',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                    .then(res => {

                        if (purpose == "filter") {
                            this.dataDropdownRegion = res.data.data;
                            this.loadingDropdownRegion = false;
                        } else if (purpose == null) {
                            this.dataDropdownRegion = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
        searchDropdownKlasifikasi(event, purpose, valueEdit){
            setTimeout(() => {

                if(valueEdit){
                    this.$refs.dklsf.filterValue = valueEdit;
                }

                if(purpose == "filter"){
                    this.loadingDropdownKlasifikasi = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-klasifikasi',
                    params: {
                        "search" : valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if(purpose == "filter"){
                        this.dataDropdownKlasifikasi = res.data.data;
                        this.loadingDropdownKlasifikasi = false;
                    }else if(purpose == null){
                        this.dataDropdownKlasifikasi = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        getDataTable(){
            this.$emit('submit');
        }
    }
}
</script>

