<!-- eslint-disable vue/no-mutating-props -->
<template>
    <Dialog v-model:visible="editItemDialog" :style="{width: '450px'}" header="Data Detail" :modal="true" class="p-fluid">
        <div class="field">
            <label>Channel Distribusi</label>
            <Dropdown dataKey="cdistribusi_id" 
                    ref="dcnldist" v-model="item.cdistribusi_id" 
                    :loading="loadingDropdownChannelDistribusi" 
                    :options="dataDropdownChannelDistribusi" 
                    :class="{ 'p-invalid': errorEdit.cdistribusi_id }" 
                    optionLabel="cdistribusi_name" optionValue="cdistribusi_id" 
                    placeholder="Pilih Channel Distribusi" :filter="true" 
                    :showClear="true" @filter="searchDropdownChannelDistribusi($event,'edit')"
            />
            <small class="p-invalid" style="color: red" v-if="errorEdit.cdistribusi_id" >{{ errorEdit.cdistribusi_id[0] }}</small>
        </div>
        <template #footer>
            <Button label="Batal" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
            <Button label="Simpan" icon="pi pi-check" class="p-button-text" @click="updateItem" :loading="loading"/>
        </template>
    </Dialog>
</template>


<script>

import { mapGetters } from "vuex";

export default {
    props: [
		"item",
    ],
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownChannelDistribusi: false,

            // dataDropdown
            dataDropdownChannelDistribusi: null,

            // edit
            editItemDialog: false,
        }
    },
    mounted() {
        this.$store.commit('setErrorEdit', {});
    },
    watch: {
        editItemDialog: {
            handler() {
                setTimeout(() => {
                    if(this.editItemDialog == true){
                        this.searchDropdownChannelDistribusi('', 'edit', this.item.cdistribusi_name);
                    }
                    this.$store.commit('setErrorEdit', {});
                }, 500);
            },
        }
    },
    computed:{
        ...mapGetters(['errorEdit']),
    },
    methods: {
        // DROPDOWN
        searchDropdownChannelDistribusi(event, purpose, valueEdit){
            setTimeout(() => {

                if(valueEdit){
                    this.$refs.dcnldist.filterValue = valueEdit;
                }

                if(purpose == "edit"){
                    this.loadingDropdownChannelDistribusi = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-channel-distribusi',
                    params: {
                        "search" : valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if(purpose == "edit"){
                        this.dataDropdownChannelDistribusi = res.data.data;
                        this.loadingDropdownChannelDistribusi = false;
                    }else if(purpose == null){
                        this.dataDropdownChannelDistribusi = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        // EDIT
		hideDialog() {
			this.editItemDialog = false;
		},
		updateItem() {
            this.loading = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/noo/update',
                data: {
                    "cust_id": this.item.cust_id,
                    "cdistribusi_id": this.item.cdistribusi_id,
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Berhasil Diupdate', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorEdit', {});
                    // this.item = {};
                    this.editItemDialog = false;
                    this.$emit('submit');
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorEdit', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal Diupdate', life: 3000,});
            });
		},
    }
}
</script>